.familyService {
    display: flex;
}


.listTitle, .listItem {
    font: var(--font-m);
}

.listTitle {
    color: var(--main-color);
    font-weight: 500;
    margin-bottom: 15px;
}

.list {
    margin-bottom: 35px;
    padding-left: 30px;
}

.listItem {
    list-style-type: disc;
}

@media screen and (max-width: 1060px) {

    .familyService {
        flex-direction: column;
        width: 100%;
    }

    .column {
        order: 1;
    }

    .block {
        order: 0;
        display: flex;
    }

    .block:first-child{
        order: 1;
        display: flex;
        flex-direction: column;
    }

    .block:last-child {
        order: 0;
        justify-content: center;
    }
    
    .button{
        align-self: center;
    }

   
}

@media screen and (max-width: 650px) {
    .img {
        width: 100%;
    }
}
