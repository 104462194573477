.discussionNav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 0 50px;
}

.prevArrow {
    transform: rotate(180deg);
    margin-right: 35px;
}



.arrow {
    fill: var(--main-color);
    cursor: pointer;
    width: 50px;
    height: 50px;
}

.arrow:not(.unactive):hover {
    fill: var(--main-active-color);
}

.pages {
    font: var(--font-l);
    font-size: 40px;
    font-weight: 500;
    margin-right: 40px;
}

.totalPages {
    color: #000;
    opacity: 0.5;
    margin-left: 6px;
}

.curPage {
    margin-right: 6px;
}

.unactive{
    fill: var(--main-color);
    opacity: 0.4;
}

.title {
    font: var(--font-xl);
    margin-bottom: 16px;
    text-align: center;
}

.subTitle {
    font: var(--font-l);
    font-size: 30px;
    font-weight: 500;
    color: var(--main-color);
    text-align: center;
}

@media screen and (max-width: 980px) {

    .discussionNav {
        justify-content: center;
        margin: 30px 0;
    }

    .arrow {
        width: 40px;
        height: 40px;
    }
    
}

@media screen and (max-width: 681px) {

    .arrow {
        width: 35px;
        height: 35px;
    }

    .pages {
        font-size: 32px;
    }
    
}

