.content {
    display: flex;
}

.aboutPage {
    padding: 40px 0 120px 0;
}

.block {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.listTitle {
    width: 100%;
    font: var(--font-l);
    font-weight: 700;
    margin: 25px;
}

.list {
    width: 100%;
    margin-bottom: 25px;
}
.description {
    width: 100%;
    margin-bottom: 25px;
    text-align: justify;
}

.list, .description {
    font: var(--font-m);
}

.secondBlock {
    padding-left: 50px;
}

.img {
    margin-bottom: 43px;
}

.name {
    margin-bottom: 8px;
    font: var(--font-m);    
    font-weight: 700;
}

.position {
    font: var(--font-m);
    color: var(--main-color);
    text-align: center;
    margin-bottom: 38px;
}


@media screen and (max-width: 840px) {
    .content {
        flex-direction: column;
    }


    .block {
        padding: 0;
    }
}


@media screen and (max-width: 460px) {
.img {width: 100%;}

}

