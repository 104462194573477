.cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 55px -8px 0 -8px;
    max-height: 600px;
    overflow: auto;
}

.row {
    display: flex;
}

.title {
    font: var(--font-xl);
    margin-bottom: 16px;
    text-align: center;
}

.subTitle {
    font: var(--font-l);
    font-size: 30px;
    font-weight: 500;
    color: var(--main-color);
    text-align: center;
}


@media screen and (max-width: 1239px) {
    .card {
        flex: 1 0 25%;
    }

    .cards {
        max-height: none;
    }
}