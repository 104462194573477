

.onlineService{
    display: flex;
    justify-content: space-around;

}

.text {
    color: #000;
    font: var(--font-m);
    text-align: justify;
    margin-bottom: 20px;
}

.button {
    margin-top: auto;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 45%;
}

.img {
    width: 400px;
}


@media screen and (max-width: 1060px) {

    .onlineService {
        flex-direction: column;
        width: 100%;
    }

    .column {
        order: 1;
        flex: 1 1 auto;
    }

    .block {
        display: flex;
        order: 0;
        justify-content: center;
        margin-bottom: 20px;
    }
    .button {
        margin: auto auto;
    }

}