
.menuBurger {
    display: none;
}

@media screen and (max-width: 1239px) {

.menuBurger {
    display: block;
    background: var(--color-l-gray);
    padding: 20px 0;
}


.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigateContent {
    background: var(--color-l-gray);
    width: 100vw;
    padding-left: 5vw;
    position: absolute;
    top: 90px;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 20px 5vw;
    transform: translateY(-100%);
    opacity: 0;
    z-index: -1;
    transition: all 0.3s;
}


.navigateContent.opened {
    opacity: 1;
    transform: none;
    z-index: 10;
}

.link {
    font-size: 20px;
    margin-bottom: 15px;
}
    
}