
.discussionForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 540px;
}

.form {
    width: 100%;
}

