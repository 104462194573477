
.cards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.title {
    font: var(--font-xl);
    margin-bottom: 16px;
    text-align: center;
}

.subTitle {
    font: var(--font-l);
    font-size: 30px;
    font-weight: 500;
    color: var(--main-color);
    text-align: center;
}

@media screen and (max-width: 840px) {
    .cards {
        height: 40vh;
        overflow-y: auto;
        flex-direction: column;
        flex-wrap: nowrap;
    }
}