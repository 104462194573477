.footer {
    background: var(--color-d-gray);
    padding: 35px 0 90px 0;
}


.footerContent {
    display: flex;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
}

.link {
    margin-bottom: 20px;
}

.logo {
    margin-bottom: 30px;
}

@media screen and (max-width: 1101px) {
    .footerContent {
        // flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -20px;
    }

    .column {
        margin: 0 20px;
        margin-bottom: 20px;
    }
}