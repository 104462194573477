

.navigate {
    padding: 25px 0;
    background: var(--color-l-gray);
}

.navigateContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


@media screen and (max-width: 1101px) {
    .navigate{
        display: none;
    }
}