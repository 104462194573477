.inputs {
    display: flex;
    flex-direction: column;
    margin: 20px auto 0 auto
}

.input {
    margin-bottom: 16px;
}

.input {
    border: none;
    padding: 17px 30px;
    font: var(--font-m);
    border-radius: 10px;
}

.white {
    background: #fff;
    color: #000;
}

.white::placeholder {
    color: #000;
}

.error {
    color: red;
    padding-left: 30px;
    margin-bottom: 20px;
    margin-top: 15px;
}

.privacy {
    margin: 32px 0 0 0;
    display: flex;
    align-items: baseline;
    cursor: pointer;
}

.privacy label{
    cursor: pointer;
}

.checkbox {
    margin-right: 10px;
}

.privacy  a {
    color: var(--main-color);
    text-decoration: none;
}
.privacy a:hover {
    text-decoration: underline;
}

.button {
    align-self: center;
    margin-top: 58px;
}


@media screen and (max-width: 980px) {
    .inputs {
        width: 100%;
    }
}