.card {
    padding: 17px 5px 17px;
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 10%;
    background: #fff;
    border: 1px solid var(--main-color);
    border-radius: 24px;
    margin-bottom: 24px;
    cursor: pointer;
    outline: none;

}

.time {
    font: var(--font-l);
    font-size: 25px;
    text-align: center;
}

.date {
    font: var(--font-l);
    opacity: 0.5;
    margin-bottom: 5px;
    text-align: center;
}


@media screen and (max-width: 941px) {
    .time {
        font-size: 18px;
    }

    .card {
        margin-bottom: 8px;
        padding: 8px 5px 8px;
    }

    .date {
        margin-bottom: 0;
    }
    .time, 
    .date {
        line-height: 18px;
    }
}

@media screen and (max-width: 825px) {
    .card {
        flex: 0 0 16%;
    }
}
@media screen and (max-width: 545px) {
    .card {
        flex: 0 0 25%;
    }
}
