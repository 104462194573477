

@font-face {
    font-family: 'Stolzl';
    src: url('../fonts/stolzl_400.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
  
@font-face {
    font-family: 'Stolzl';
    src: url('../fonts/stolzl_500.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}
  
@font-face {
    font-family: 'Stolzl';
    src: url('../fonts/stolzl_700.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}
  