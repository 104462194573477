.form {
    width: 50%;
}

.title {
    font: var(--font-xl);
    margin-bottom: 16px;
    text-align: center;
}

.subTitle {
    font: var(--font-l);
    font-size: 30px;
    font-weight: 500;
    color: var(--main-color);
    text-align: center;
}

@media screen and (max-width: 980px) {
    .form {
        width: 100%;
    }
}