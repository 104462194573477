.popupWrapper{
    background: rgba($color: #F3F3F3, $alpha: 0.3);
    
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 100;
    align-items: center;
    justify-content: center;
    display: none;
}

.open {
    display: flex;
}

.cross {
    font-size: 35px;
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 15px;

}

.board{
    position: relative;
    padding: 55px 50px;
    background: var(--color-l-gray);
    border-radius: 23px;
    z-index: 101;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4)
}