

.header {
    
}

.headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
}

.text {
    font: var(--font-l);
    color: #000;   
}

@media screen and (max-width: 1101px) {
    .headerContent {
        display: none;
    }
    
}