


.burgerButton {
    position: relative;
    display: block;
    width: 40px;
    height: 30px;
    cursor: pointer;
    background: none;
}


.stick,
.burgerButton::before,
.burgerButton::after {
    position: relative;
    display: block;
    width: 100%;
    height: 5px;
    border-radius: 50px;
    background: #000;
    transition: all 0.2s;
}


.burgerButton::before,
.burgerButton::after {
    content: '';
    position: absolute;
}

.burgerButton::before {
    top: 0;
}

.burgerButton::after {
    bottom: 0;
}


.stick.active {
    width: 0;
}

.active::before {
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
}

.active::after {
    bottom: 50%;
    transform: rotate(-45deg) translateY(50%);
    
}