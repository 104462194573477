.discussion {
    background: var(--color-l-gray);
    padding: 90px 0;
}

.discussionContent {
    display: flex;
    flex-direction: column;
}

