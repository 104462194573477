@import-normalize; /* bring in normalize.css styles */
@import 'reset.scss';
@import 'fonts.scss';
@import 'variables/global.scss';


.app {
    font-family: var(--font-family-main);
    min-height: 100vh;
}

.content-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

}

.container {
    max-width: 90%;
    margin: 0 auto;
}

.page-wrapper {
    flex-grow: 1;
    overflow: hidden;
}

.colored-text {
    color: var(--main-color);
}