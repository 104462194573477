.active {
    background: var(--main-color);
    color: #fff;
}

.button {
    padding: 21px 75px;
    margin: 0 30px;
}

.buttons {
    display: flex;
    justify-content: center;
    margin: 121px -30px 0 -30px;
}

.title {
    font: var(--font-xl);
    margin-bottom: 16px;
    text-align: center;
}

.subTitle {
    font: var(--font-l);
    font-size: 30px;
    font-weight: 500;
    color: var(--main-color);
    text-align: center;
}

@media screen and (max-width: 450px) {
    .buttons {
        flex-direction: column;
    }

    .button {
        margin-bottom: 20px;
    }
}