

.tariffCard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    flex: 0 0 22%;
    background: #FFFFFF;
    border: 1px solid rgba(228, 228, 228, 0.5);
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.03);
    border-radius: 24px;

}

.title {
    font: var(--font-l);
    color: var(--main-color);
    font-weight: 700;
    margin-bottom: 23px;
    width: 60%;
    text-align: center;
    min-height: 50px;
}

.discount {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transform: translate(20%, -40%);
    background: url('../../../shared/assets/img/icons/discount-bg.svg') center no-repeat;
}

.price {
    font: var(--font-l);
    font-weight: 700;
    margin-bottom: 5px;
}

.crossed {
    font: var(--font-m);
    text-decoration: line-through;
    display: block;
    min-height: 17px;
}

.info {
    font: var(--font-m);
}



@media screen and (max-width: 710px) {
    .tariffCard {
        flex: 0 0 40%;
    }
}