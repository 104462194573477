.button {
    padding: 16.6px 30px;
    border-radius: 50px;
    font: var(--font-l);
    font-weight: 500;
    color: #fff;
}

.white {
    color: #000;
    background: #fff;
    font-weight: 400;
    border: 1px solid rgba(228, 228, 228, 0.5);
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.03);
}

.green {
    background: var(--main-color);
}

.green:hover {
    background: var(--main-active-color);
}

.blue, .blue_bordered {
    background: var(--main-color);
}

.blue_bordered {
    border: 1px solid #fff;
}

.blue:hover, .blue_bordered:hover {
    background: var(--main-active-color);
}

.bordered {
    background: transparent;
    color: var(--main-color);
    border: 1px solid var(--main-color);
}

.bordered:hover {
    background: var(--main-color);
    color: #fff;
}